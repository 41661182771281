<template lang="pug">
article.franchise-finances
  .franchise-finances__cont.cont
    h2.franchise-finances__title.title Финансовые показатели
    ul.franchise-finances__list
      li.franchise-finances__item(v-for="(item, i) in list", :key='i')
        span.franchise-finances__note {{ item.note }}
        span.franchise-finances__value.title {{ item.value }}
    //.franchise-finances__video
    //  iframe.franchise-finances__frame(
    //      src="https://www.youtube.com/embed/jyY9GZGCVGs",
    //      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
    //      allowfullscreen=true,
    //      loading="lazy"
    //    )
</template>

<script>
import '@/assets/styles/components/franchise-finances.sass'

export default {
  name: 'FranchiseFinances',
  data () {
    return {
      list: [
        { note: 'Средняя выручка', value: '1,5 млн. руб.' },
        { note: 'Чистая прибыль', value: '500 тыс. руб.' },
        { note: 'Рентабельность', value: '50%' },
        { note: 'Стартовые инвестиции', value: '3 млн руб.' }
      ]
    }
  }
}
</script>
