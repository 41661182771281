<template lang="pug">
article.franchise
  .franchise__cont.cont
    h1.franchise__caption.caption Откройте Музей «Шоколадный Выборг» в своём городе
    p.franchise__text
      | Музей «Шоколадный Выборг» — это формат заведения, включающий экспозицию, посвященную истории
      | шоколада, магазин шоколада ручной работы и интерактивную часть — мастер-классы для
      | посетителей по приготовлению шоколада.
    ul.franchise__list
      li.franchise__item(v-for="item in list", :key="item")
        span.franchise__headline.headline {{item.headline}}
        p.franchise__note {{item.note}}
</template>

<script>
import '@/assets/styles/components/franchise.sass'
export default {
  name: 'TheFranchise',
  data () {
    return {
      list: [
        { headline: 'Низкая стоимость запуска', note: 'Групповые и индивидуальные, детские и взрослые группы' },
        { headline: 'Высокая окупаемость', note: 'Узнайте историю шоколада и создайте свой собственный рецепт' },
        { headline: 'Низкая конкурентность', note: 'Шоколадные вечеринки и всевозможные интерактивы' }
      ]
    }
  }
}
</script>
