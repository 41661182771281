<template lang='pug'>
.franchise-project
  .franchise-project__cont.cont
    h2.franchise-project__title.title Готовый бизнес-проект Музея
    span.franchise-project__headline.headline Дизайн-проект Музея
    p.franchise-project__text
      | Включает в себя не только оформление интерьера музея, но и комплектацию объекта под ключ
      | (подбор декоративных материалов, оборудования, освещения, мебели и фурнитуры) и
      | художественное оформление витрин магазина.
    .franchise-project__download
      picture.franchise-project__download-picture
        source(:srcset="`${pathDownloadImage}_328.webp, ${pathDownloadImage}_656.webp 2x`", type="image/webp", media="(max-width: 520px)")
        source(:srcset="`${pathDownloadImage}_1176.webp`", type="image/webp")
        source(:srcset="`${pathDownloadImage}_328.jpg, ${pathDownloadImage}_656.jpg 2x`", media="(max-width: 520px)")
        img.franchise-project__download-image(:src="`${pathDownloadImage}_1176.jpg`", alt="Пример дизайн проекта Музея «Шоколадный Выборг»", title="Пример дизайн проекта Музея «Шоколадный Выборг»", loading="lazy", width="1176", height="500")
      a.franchise-project__download-link.link-more(href="#franchise-contacts") Скачать
    span.franchise-project__headline.headline
      | Разработка и производство тематического оснащения
    p.franchise-project__text
      | Это не просто музей, а необычный магазин со своей концепцией: его витрина выглядит, как
      | самая настоящая выставка шоколада невероятных форм и размеров.
    .franchise-project__works
      span.franchise-project__works-subtitle.ff-garamond Мы сами производим
      ul.franchise-project__works-list
        li.franchise-project__works-item(v-for="(item, i ) in projectWorks", :key="i")
          picture.franchise-project__works-picture
            source(:srcset="`${item.path}_328.webp, ${item.path}_656.webp 2x`", type="image/webp", media="(max-width: 520px)")
            source(:srcset="`${item.path}_276.webp`", type="image/webp")
            source(:srcset="`${item.path}_328.jpg, ${item.path}_656.jpg 2x`", media="(max-width: 520px)")
            img.franchise-project__works-image(:src="`${item.path}_276.jpg`", :alt="item.alt", :title="item.alt", loading="lazy", width="276", height="184")
          span.franchise-project__works-name {{item.name}}
    h2.franchise-project__title.title Необходимый штат
    p.franchise-project__text-big 1 шоколатье, 1 упаковщик, 1 работник кухни, 1 администратор, 2 продавца
    h2.franchise-project__title.title  Обучающие материалы и обучение
    ul.franchise-project__list
      li.franchise-project__item(v-for="(item, i) in projectList", :key="i") {{item}}
</template>

<script>
import '@/assets/styles/components/franchise-project.sass'
export default {
  name: 'FranchiseProject',
  data () {
    return {
      pathDownloadImage: '/images/franchise-project/franchise-project-1',
      projectWorks: [
        { path: '/images/franchise-project/franchise-project-2', alt: 'Силиконовые формы для шоколада', name: 'Силиконовые формы' },
        { path: '/images/franchise-project/franchise-project-3', alt: 'Тематическая бутафория городских достопримечательностей', name: 'Тематическую бутафорию под ваш город' },
        { path: '/images/franchise-project/franchise-project-4', alt: 'Шоколадная сувенирная продукция для бизнеса', name: 'Сувенирную продукцию для бизнеса' }
      ],
      projectList: [
        'Школа обучения приготовления шоколада и конфет',
        'Разработка экспозиции и авторских экскурсий «История города в шоколаде»',
        'Мастер-классы по приготовлению шоколада и другие интерактивности для посетителей'
      ]
    }
  }
}
</script>
