<template lang="pug">
article.franchise-contacts#franchise-contacts
  .franchise-contacts__cont.cont
    h2.franchise-contacts__title.title Хотите получить дизайн-проект и консультацию по открытию музея?
    p.franchise-contacts__text Заполните форму, и наши менеджеры свяжутся с вами в ближайшее время
    form.franchise-contacts__form(@submit.prevent="sendForm")
      label.franchise-contacts__field
        input.franchise-contacts__input.text.input(type="text", v-model="name", placeholder='Имя')
      label.franchise-contacts__field
        input.franchise-contacts__input.text.input(
          :class="{ 'input-invalid': error }",
          type="tel", v-model="phone",
          placeholder='Телефон*',
          pattern="[0-9-+() ]*",
          maxlength=20,
          required=true)
      button.franchise-contacts__button.button-white Отправить
      span.franchise-contacts__small-text
        | и принять условия
        |
        router-link.franchise-contacts__policy-link(:to="{name: 'Policy'}") Политики конфиденциальности
</template>

<script>
import '@/assets/styles/components/franchise-contacts.sass'
import { consultation } from '../../assets/scripts/API'
import Storage from '../../assets/scripts/Storage'

export default {
  name: 'FranchiseContacts',
  data () {
    return {
      name: '',
      phone: '',
      error: false
    }
  },
  methods: {
    async sendForm () {
      const result = await consultation(this.name, this.phone)
      if (result.status === 'success') {
        this.error = false
        Storage.showThanks = true
        await this.$router.push({ name: 'Thanks' })
      } else {
        this.error = true
      }
    }
  }
}
</script>
