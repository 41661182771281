<template lang="pug">
article.franchise-causes
  .franchise-causes__cont.cont
    h2.franchise-causes__title.title 10 причин стать нашим партнером
    ul.franchise-causes__list
      li.franchise-causes__item(v-for="(item, i) in list", :key="i") {{item}}
</template>

<script>
import '@/assets/styles/components/franchise-causes.sass'
export default {
  name: 'FranchiseCauses',
  data () {
    return {
      list: [
        'Рабочая бизнес-модель',
        'Мы честны перед нашими партнерами: посещение рабочего Музея',
        'Собственный тренинг-центр: обучение партнера и персонала',
        'Обучающие мастер-классы',
        'Оборудование и формы собственного производства',
        'Без роялти, без паушальных и скрытых платежей',
        'Средняя выручка музея 1 500 000 рублей в месяц',
        'Поддержка отдела маркетинга',
        'Разработка дизайн-проекта музея',
        'Быстрый запуск проекта – 2 месяца'
      ]
    }
  }
}
</script>
