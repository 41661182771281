<template lang="pug">
head-meta(
  title-page="Франшиза Музея «Шоколадный Выборг»: готовый бизнес",
  description="Предлагаем открыть собственный магазин-музей «Шоколадный Выборг» в вашем городе. Готовый бизнес-проект, обучение и сопровождение запуска: от дизайн-проекта до первых посетителей."
)
the-franchise
the-franchise-project
own-production(block-name="Отлаженные бизнес-процессы", block-mod="franchise")
the-franchise-causes
the-franchise-contacts
the-franchise-finances
</template>

<script>
import TheFranchise from '../components/franchise/TheFranchise'
import TheFranchiseCauses from '../components/franchise/TheFranchiseCauses'
import TheFranchiseContacts from '../components/franchise/TheFranchiseContacts'
import TheFranchiseFinances from '../components/franchise/TheFranchiseFinances'
import TheFranchiseProject from '../components/franchise/TheFranchiseProject'
import OwnProduction from '../components/OwnProduction'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'FranchisePage',
  components: {
    HeadMeta,
    OwnProduction,
    TheFranchiseProject,
    TheFranchiseFinances,
    TheFranchiseContacts,
    TheFranchiseCauses,
    TheFranchise
  }
}
</script>
